.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination a {
    padding: 1px 12px;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 15px;
  }
 