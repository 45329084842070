 body {
  display: flex; flex-direction: column;
}
/* customDatepicker.css */

.inputdate1 {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  border-bottom: 1px solid #989898 !important;
  border-radius: 0 !important;
  margin-bottom: 20px;
  outline: none;
  background-color: transparent !important;
}


@media (max-width: 767px) {
  .cprogress

  {
    height:90px;
    width:90px;
  }
  .userdp
  {
    height:80px;
    width:80px;
    border-radius: 120px;
  }
  .web_nav {
    display: none !important;
  }
  

  .myCarousel {
    background: #fafafa;
    margin-top: -12%;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 230px;
  }
  
}
@media (min-width: 767px) {
  .cprogress

{
  height:115px;
  width:115px;
}
.userdp
{
  height:100px;
  width:100px;
  border-radius: 120px;
}
  .mob_nav {
    display: none;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -12%;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 35dvh;
  }
  
 
}
@media (max-width: 1180px) {
.dashbar{
  width:22%;
}
}
@media (max-width: 768px) {
  .filter_parent {
    display: none !important;
  }
}


.pac-container {
  z-index:1000 !important;
  position: absolute;
}



@media (max-width: 767px) {
  .filter_parent_application {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hide_img {
    display: none !important;
  }
}
@media (min-width: 920px) {
  .display_img {
    display: none !important;
  }
}

:root {
  margin: 0;
  padding: 0;
}
.input1 {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  border-bottom: 1px solid #989898 !important;
  border-radius: 0 !important;
  margin-bottom: 20px;
  outline: none;

  background-color: transparent !important;
}


.input1:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #ff5c09 !important;
}
.input1:hover {
  outline: none !important;
  border-bottom: 1px solid #ff5c09 !important;
}



.forgot_password {
  color: #ff5c09;
  cursor: pointer;
  text-decoration: underline;
}



.nav_login {
  background-color: #3352D0;
  border: none;
  border-radius: 8px;
  color: #ffffff !important;
  padding: 8px 20px;
  width:150px;
}
/* .nav_login:hover {
  background-color: white;
  border: 2px solid #3352D0;
  border-radius: 8px;
  color: #000000;
  padding: 8px 20px;
  transition: 500ms;
} */

.nav_cook_login {
  background-color: #00b0a0;
  border: none;
  border-radius: 8px;
  color: #000000;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav_cook_login:hover {
  background-color: white;
  border: 2px solid #00b0a0;
  border-radius: 8px;
  color: #000000;
  padding: 8px 20px;
  transition: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_btn {
  border: 2px solid #ff5c09 !important;
  border-radius: 8px;
  color: #ff5c09;
  padding: 8px 20px;
}
.signup_btn:hover {
  background-color: #ff5c09 !important;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 8px 20px;
  transition: 800ms;
}
.terms_and_conditions {
  color: #ff5c09;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;
  text-wrap: wrap;
}
.toggle_button {
  color: #ff5c09;
  border: 2px solid #ff5c09;
  border-radius: 8px;
  padding: 8px 20px;
  background-color: white;
}
.toggle_button:hover {
  background-color: #ff5c09;
  color: white;
  transition: 500ms;
}
.toggle_button_active {
  border-radius: 8px;
  border: none;
  padding: 8px 20px;
  background-color: #ff5c09;
  color: white;
}

.login_btn {
  height: 48px !important;
  width: 200px !important;
  border-radius: 50px !important;
  background-color: #ff5c09 !important;
  color: #ffffff;
  border-radius: 15px !important;
  border: none !important;
  font-size: 18px;
}
.sidebar {
  /* height: 600px; */
  padding: 14px;
  width: "100%";
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.16);

}


.sidebar-menu-body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 8px 8px 8px 0px;

}

.sidebar-menu-nav {
  height: 30px;
  width: 230px;
  padding: 5px 5px;
  /* border-bottom: solid 1px #e6e3e3; */
  display: flex;
  justify-content: flex-start;
  align-items: left;
  cursor: pointer;
  margin-left: -19px;
  transition: 500ms;

  
}


.sidebar-menu-nav-link {
  color: #000000;
  padding:5px 5px 5px 20px;
}



.sidebar-menu-nav .active-link {
  color:#fff;
  background:#ff5c09;
  padding:5px 5px 5px 20px;
  width:100%;
height:34px;
}
.sidebar-menu-nav .active-links {
  color:#fff;
  background:#ff5c09;
  padding:5px 10px 5px 10px;
  width:100%;
height:34px;
}
@media only screen and (max-width: 767px) {
.arrow{justify-content: end;
}
}

@media only screen and (min-width: 768px) {
  .arrow{
  justify-content: end;
  }
  }
@media screen and (min-width: 1200px) {
  .sidebar-menu-nav .active-link {
    color:#fff;
    background:#ff5c09;
    padding:5px 5px 5px 20px;
    width:94%;
  height:34px;
  }
  

  .sidebar-menu-nav .active-links {
    color:#fff;
    background:#ff5c09;
    padding:5px 10px 5px 10px;
    width:94%;
  height:34px;
  }
 
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .sidebar-menu-nav .active-link {
    color:#fff;
    background:#ff5c09;
    padding:5px 5px 5px 20px;
    width:65%;
  height:34px;
  }
  .sidebar-menu-nav .active-links {
    color:#fff;
    background:#ff5c09;
    padding:5px 10px 5px 10px;
    width:65%;
  height:34px;
  }
  
}

.sidebar-menu-nav:hover {
 
  .sidebar-menu-nav-link {
    /* color: #ff5c09; */
    /* border-radius: 10px;
    */
  -webkit-transition: color 0.8s ease;
  -moz-transition: color 0.8s ease;
  -ms-transition: color 0.8s ease;
  -o-transition: color 0.8s ease;
  transition: color 0.8s ease;
  

  }
}


.sidebar-menu-sub {
  width: 300px;
  padding: 5px 5px;
}

.sidebar-menu-sub-toggle {
  border: none;
  background-color: transparent;
}

.dash_card {
  height: auto;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
}

.navMenu {
  color: #000000 !important;
  font-weight: 400;
}
.search_parent {
  padding: 4px;
  border-radius: 15px;
  border: 2px solid #9e9e9e;
}
.search_parent:hover {
  border: 2px solid #ff5c09;
}
.search_parent:focus {
  border: 2px solid #ff5c09;
}
.search {
  border: none !important;
  border-radius: 30px !important;
  background-color: transparent !important;
}
.search:focus {
  box-shadow: none !important;
}

.view_profile {
  background-color: #fff !important;
  height: 25px;
  width: 75px;
  box-shadow: none !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  color:#ff5c09;
  padding:5px;
  border: solid 1px #ff5c09;
}
.view_profile:hover {
  background-color: #fff !important;
  height: 26px;
  width: 76px;
  padding:5px;
  box-shadow: none !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  color:#ff5c09 !important;
  border: solid 1px #ff5c09 !important;
}
.filter_btn {
  background-color: transparent !important ;
  color: #ff5c09 !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  padding:8px;
  margin-bottom: 10px;
  font-size: 18px !important;
  font-weight: 600 !important;
 
  border: none;
}

.banner_child {
  width: 40%;
  min-height: 75%;
  background-color: #ff5c09;
  border-radius: 50%;
  position: absolute;
  bottom: 5%;
  right: 10%;
}
.box {
  height: 15%;
  width: 25%;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 35%;
  top: 30%;
}
.box_2 {
  height: 15%;
  width: 25%;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  right: 2%;
  top: 60%;
}

.banner_box {
  height: 280px;
  width: 275px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  color: rgb(48, 48, 48);
}
.banner_box:hover {
  background-color: #f9bea0;
  transition: 500ms;
  color: black;
  cursor: pointer;
}
.home_card {
  height: calc(200vw / 6);
  width: calc(200vw / 6);
  border-radius: 51%;
  background-color: #ffb996;
  position: relative;
}
.card_circle {
  height: 50%;
  width: 55%;
  border-radius: 15px;
  background-color: #ffffff;
}
.card_banner {
  position: absolute;
  height: 84px;
  width: 253px;
  border-radius: 15px;
  background-color: white;
  top: 25%;
  left: 70%;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.36);
}
.card_banner_2 {
  position: absolute;
  height: 84px;
  width: 253px;
  border-radius: 15px;
  background-color: white;
  top: 25%;
  right: 70%;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.36);
}
.testimonials_parent {
  min-height: 30dvh;
  width: 100%;
  background-color: #ffb996;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}

.black {
  background-color: #000000;
}

.test_star {
  height: 12%;
  width: 40%;
  border-radius: 8px;
  background-color: #000000;
}

.plan_card_client {
  height: 100%;
  width: 100%;
  border: 2px solid #ff5c09;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

.plan_card_client_child {
  height: 100%;
  width: 80%;
  background-color: #ffebe0;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 8px;
}

.plan_card_cook {
  border: 1px solid #ff5c09;
  border-radius: 15px;
  padding: 14px;
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}

.progress_bar {
  height: 80px !important;
  width: 80px !important;
  padding: 8px;
}
.plan_card_mobile {
  background-color: #0c08ce;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 8px;
}
.progress_bar_left {
  height: 80px !important;
  width: 80px !important;
}

.post_job {
  padding: 32px 28px;
  border-radius: 15px;
  background-color: white;
}
a {
  text-decoration: none !important;
  color:#a020f0;
}

.bcrumb:hover {
  border-bottom: solid 1px #a020f0;
 
}
.help:hover {
  text-decoration: underline;
 
}

.cookLogin {
  background-image: url("./Assets/Images/4105860_2152177.jpg");
  height: auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.cookSignup {
  
  height: 80vh;
  background-repeat: no-repeat;
  background-size: contain;
}



input[type="switch"] {
  background-color: #000000;
}

.loginBox {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
eye_icon_login {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(-15%, -50%);
  transition: all 0.4s ease-in-out;
  font-size: 20px;
  color: black;
}

.eye_icon_login:hover {
  background-color: #dbdbdb;
  padding: 3px;
  font-size: 20px;
  border-radius: 50%;
}

input.input1::placeholder {
  color: #c4c4c4;
  
}
textarea.input1::placeholder
{
  color:#c4c4c4;
}

select {
  appearance: menulist !important;
}

.home-img {
  height: 40px;
  width: 40px;
}

.home-text {
  font-size: 14px;
}

.edit_button_profile {
  height: 6dvh;
  width: 100%;
  margin-top: 4dvh;
  margin-bottom: 3dvh;
  background-color: black;
  border: none;
  border-radius: 15px;
  font-size: 2.5dvh;
  font-weight: 600;
  text-transform: capitalize;
}
.edit_button_profile:hover {
  height: 6dvh;
  width: 100%;
  /* margin-top: 4dvh;
  margin-bottom: 3dvh; */
  border-radius: 15px;
  border: 2px solid #ff5c09;
  background-color: white;
  font-size: 2.5dvh;
  color: black;
  font-weight: 600;
}

.offcanvas {
  max-width: 80%;
}
.cards {
  border-radius: 15px;
  background-color: #ffffff;
}

/* .swiper-button-next,
.swiper-button-prev {
  background-image: url(./Assets//Images//next.png); */
/*background-repeat: no-repeat;
  background-size: contain;
  background-position: center; */
/* color: red !important;
 font-size: 50px !important;
 height: 4px !important;
 width: 4px !important;
} */

.swiper-button-next::after {
  /* border: 2px solid red;
  height: 50px !important;
  width: 50px !important;
  color: red !important; */
  font-size: 18px !important;
  color: #ff5c09;
  font-weight: 800 !important;


  /* background-image: url(./Assets/Images/next.png) !important; */
}
.swiper-button-prev::after {
  /* border: 2px solid red;
  height: 50px !important;
  width: 50px !important;
  color: red !important; */
  font-size: 18px !important;
  color: #ff5c09;
  font-weight: 800 !important;


  /* background-image: url(./Assets/Images/next.png) !important; */
}

.card
{
  border:none !important;
  border-radius: 10px;
}

.card-header
{
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}




.label {
  font-weight:500;
  color:#989898;
}

.rdo {
  
  margin: 20px;
}



.rbt-token
{
color:#000 !important;
background-color:#f8ccb6 !important
}
.rbt
{
  border:none !important;
}

.form-control
{
  display: block;
    width: 100%;
    /* padding: 0.375rem 0.75rem; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding-top:-10px !important;
    /* color: var(--bs-body-color); */
    appearance: none;
    border: none !important;
    border-bottom: 1px solid #989898 !important;
    border-radius: 0 !important;
    transition: none !important;
}

.orange
{
  color:#ff5c09;
}

.text-heading
{
  color:#989898;
  font-size:14px;
}
.text-details
{
  color:#000000;
  margin-top:-15px;
  font-weight:400;
  text-wrap: wrap;
}

.label {
  font-weight:500;
  color:#989898;
}

.rbt-aux
{
  display:none !important;
}

.is-valid, .is-invalid
{
  background-image: none !important;
}
.form-check-input:checked
{
  background-color: #ff5c09;
  border-color: #ff5c09;

}

.switchButton
{
  margin-top:60px;
  margin-left:-130px;
}
.label1 {
  font-weight:500;
  color:#989898;
  margin-bottom: 10px;
}


.circle1,
.circle2 {
  background: white;
  background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.1)
  );
  height: 15rem;
  width: 15rem;
  position: absolute;
  border-radius: 50%;
}

.circle1 {
  top: 10%;
  left: 10%;
  transform: translate(-10%, -10%);
}

.circle2 {
  top: 90%;
  left: 40%;
  transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.flex-container
{
  width: auto;
  position: relative;
  overflow: auto;
  z-index: 1;
}
.navIcon
{
  height:17px;
  width:17px;
  margin-bottom: 3px;
}
.cancelBtn
{
  background-color: white !important;
  border: 1px solid #ff5c09 !important;
  color: #ff5c09;
  box-shadow: none;
  border-radius: 10px;
  width: 130px;
  height: 40px;

  font-size: 18px;
}

.cancelBtn:hover,
.cancelBtn:focus {
  height: 41px;
  width: 131px;
  background-color: white !important;
  border: 1px solid #ff5c09 !important;
  color: #ff5c09 !important;
  box-shadow: 0 0 5px #989898; /* Add appropriate box-shadow values */
}

.saveBtn
{
  background-color: #ff5c09 !important;
  border: 1px solid #fff !important;
  color: #fff;
  box-shadow: none;
  border-radius: 10px;
  width: 130px;
  height: 40px;
  font-size: 18px;
}


.notfoundbtn{
  background-color:blue !important;
  border: 1px solid #fff !important;
  color: #fff;
  box-shadow: none;
  border-radius: 10px;
  width:200px;
  height: 40px;
  font-size: 18px;
}

.saveBtn:hover {
  height: 41px ;
  width: 131px;
  background-color: #ff5c09 !important;
  box-shadow: #989898;
  border:none;

}

.apply_btn
{
  background-color: #ff5c09 !important;
  border: 1px solid #fff !important;
  color: #fff;
  box-shadow: none;
  border-radius: 10px;
  width: 100px;
  height: 40px;

  font-size: 18px;
}


.apply_btn:hover {
  height: 40px ;
  width: 100px;
  background-color: #ff5c09 !important;
  box-shadow: #989898;
  border:none;

}


.cancel_btn
{
  background-color: white !important;
  border: 1px solid #ff5c09 !important;
  color: #ff5c09;
  box-shadow: none;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  font-size: 18px;
}
.cancel_btn:hover {
  height: 41px ;
  width: 101px;
  background-color: white !important;
  border: 1px solid #ff5c09 !important;
  color: #ff5c09 !important;
  box-shadow: #989898;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.homeCard{
border-radius: 10px;
background: white;
padding:12px 5px;
margin:5px;
box-shadow: 0 5px 15px rgba(145, 92, 182,0.2);
height:auto;
}
.homeCard:hover
{
  box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
}
.detailCard
{
  width:49%;
}

@media (max-width: 767px) {

.detailCard
{
  width:98%;
}
}

.homeIcon
{
  height:24px;
  width:24px;
}
.contactCard{
  border-radius: 10px;
  background: white;
  padding:6px 10px;
  margin:5px;
  width:45px;
  box-shadow: 0 5px 15px rgba(145, 92, 182, .2);
  }
  .contactCard:hover{
    box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
    }
td
{
  font-size: 14px;
}
.active>.page-link, .page-link.active
{
  background-color: #ff5c09;
  border: none;
}
.page-link
{
  color:#ff5c09
}
 .active
 {
  color:#ff5c09
 }
.multi-range-slider .ruler {
	margin: 10px 0px -5px 0px;
	/* display: flex; */
	display: none !important;
	overflow: hidden;
}
.cookjob
{
  width:32%;
}
.progress-bar
{
  background-color: #ff5c09;
  border-radius: 20px;
}
.modal-header
{
  border-bottom:none;
}

.modal-footer
{
  border-top:none;
  justify-content: flex-start;
}

input#session-date{
  position:relative;
  overflow:hidden;
}
input#session-date::-webkit-calendar-picker-indicator{
  display:block;
  top:0;
  left:0;
  background: #0000;
  position:absolute;
  transform: scale(12)
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
{
  border-bottom: 3px solid #ff5c09;
 
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #ff5c09;
      background-color: #fff;
      border-bottom: solid 3px #ff5c09 !important;
      border:none;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color : none;
  }

  .nav-link
  {
    color:#000;
  }

.plan-card {
  display: inline-block;
  /* Add any other styling for your column */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .plan-card {
    overflow-x: scroll;
  }
}
.container {
  overflow-x: auto;
  white-space: nowrap;
}


.planCard{

  padding:8px 20px;
  margin:5px;
  
  }

  .react-loading-skeleton
  {
    border-radius: 10px;
    background-color:#fff;
    --highlight-color:#fff;
    --base-color:#fafafa;
   
  }
  .dropdown-toggle::after{
   display:none;
}
.switch-handle {
  position: absolute;
 
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-handle2 {
  position: absolute;
 
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #ff5c09 40%, #f0d9cd);
  background-image: -webkit-linear-gradient(top, #ff5c09 40%, #f0d9cd);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-handle2:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #f0d9cd, #ff5c09);
  background-image: -webkit-linear-gradient(top, #f0d9cd, #ff5c09);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.switch-input:checked~.switch-handle {
  left: 84px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
      ========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.swiper-3d .swiper-slide-shadow
{
  background:rgba(248,247,203,0.5) !important;
}


.modal-content {
 width:400px;
 max-height: auto;
  padding: 20px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
  overflow-y: auto;
 
}
.select-no-border {
  border: none;
}

.modal-title
{
  margin-top: -20px;
  margin-left: -10px;
  
}
.swiper-pagination-bullet

{
  width:20px !important;
  border-radius: 10px !important;
} 

.swiper-pagination-bullet-active
{
  background: #ff5c09 !important;
}


/* .show{
  height: auto;
  } */
  .modal-backdrop.show
  {
    opacity: rgba(0,0,0,0.5);
  }

  .nav-link-item{
 
    padding: 8px;
    transition: all 0.3s ease-in-out;
    color:#000;
    font-weight: 500;
}
.invalid-feedback
{
  text-wrap: wrap ;
}
.text_container_child
{
  margin-top: 20%;
}






.rmsc .options {
  max-height: 260px;
  overflow-y: auto;
  z-index: 1001;
  margin: 0;
  position: relative;
  padding-left: 0;
}


.rmsc .options li {
  list-style: none;
  margin: 0;
  position: relative;
  z-index: 1001;

}
--------------



[data-rsbs-overlay] {
  border-top-left-radius: var(--rsbs-overlay-rounded);
  border-top-right-radius: var(--rsbs-overlay-rounded);
  display: flex;
  background: var(--rsbs-bg);
  flex-direction: column;
  height: var(--rsbs-overlay-h);
  transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
  will-change: height;
  background: #0c08ce;
}
[data-rsbs-overlay]:focus {
  outline: none;
}
[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
    0 -1px 0 rgba(38, 89, 115, 0.05);
}
[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: var(--rsbs-max-w);
  margin-left: var(--rsbs-ml);
  margin-right: var(--rsbs-mr);
}
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 0;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: var(--rsbs-backdrop-bg);
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}
[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: '';
  pointer-events: none;
  background: var(--rsbs-bg);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
  will-change: transform;
}
[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}
[data-rsbs-header] {
  text-align: center;
  user-select: none;
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
  z-index: 0;
  padding-top: calc(20px + env(safe-area-inset-top));
  padding-bottom: 8px;
}
[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: var(--rsbs-handle-bg);
}
@media (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}
[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}
[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
[data-rsbs-scroll]:focus {
  outline: none;
}
[data-rsbs-has-footer='false'] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}
[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}
[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
    0 2px 0 var(--rsbs-bg);
  overflow: hidden;
  z-index:-1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable='true'],
[data-rsbs-is-dismissable='false']:matches([data-rsbs-state='opening'], [data-rsbs-state='closing']) {
  & :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > * {
    opacity: var(--rsbs-content-opacity);
  }
  & [data-rsbs-backdrop] {
    opacity: var(--rsbs-backdrop-opacity);
  }
}

[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}

[data-rsbs-overlay] {
  border-top-left-radius: var(--rsbs-overlay-rounded);
  border-top-right-radius: var(--rsbs-overlay-rounded);
  display: flex;
  background: var(--rsbs-bg);
  flex-direction: column;
  height: var(--rsbs-overlay-h);
  transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
  will-change: height;
}
[data-rsbs-overlay]:focus {
  outline: none;
}
[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
    0 -1px 0 rgba(38, 89, 115, 0.05);
}
[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: var(--rsbs-max-w);
  margin-left: var(--rsbs-ml);
  margin-right: var(--rsbs-mr);
}
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: -1;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: var(--rsbs-backdrop-bg);
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}
[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: '';
  pointer-events: none;
  background: var(--rsbs-bg);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
  will-change: transform;
}
[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}
[data-rsbs-header] {
  text-align: center;
  user-select: none;
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
  z-index: -1;
  padding-top: calc(20px + env(safe-area-inset-top));
  padding-bottom: 8px;
}
[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: var(--rsbs-handle-bg);
}
@media (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}
[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}
[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
[data-rsbs-scroll]:focus {
  outline: none;
}
[data-rsbs-has-footer='false'] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}
[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}
[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
    0 2px 0 var(--rsbs-bg);
  overflow: hidden;
  z-index: -1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable='true'],
[data-rsbs-is-dismissable='false']:matches([data-rsbs-state='opening'], [data-rsbs-state='closing']) {
  & :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > * {
    opacity: var(--rsbs-content-opacity);
  }
  & [data-rsbs-backdrop] {
    opacity: var(--rsbs-backdrop-opacity);
  }
}

[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}



/* Testimonial */

.carousel-root {
  width: 100% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}




.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
 
  font-size: 17px;
}

.myCarousel h4 {

  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (min-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 95% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 220px;
  }

  .carousel .slide img {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
  }
}

.carousel.carousel-slider .control-arrow
{
  display: none;
}


.btn-primary:hover
{
  background-color: #ff5c09 ;
  border:none;
}

.overviewcard
{
  width: 100%;
  height: 64px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
}

.overviewcardContact
{
  width: 100%;
  height: 20px;
  background-color: white;
  display: flex;

  padding: 10px 10px 10px 40px;

}

.overviewcircle{
 
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    

}

.overviewcircleContact{

    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2px;
    margin-right: 5px;

}
@media (min-width: 767px) {
.contacticonCard{
  margin-top: -60px;
  align-content: end;
  justify-content: end;
}
}
@media (min-width: 767px) {
  .jobCard{
    margin-top: -20px;
    align-content: end;
  }
  }

  body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
footer{
    margin-top: auto;
}

.modalcancelBtn
{
  background-color: white !important;
  border: 1px solid #ff5c09 !important;
  color: #ff5c09 !important;
  box-shadow: none;
  border-radius: 10px;
  width: 80px;
  height: 35px;
  font-size: 14px;
}
.modalcancelBtn:hover {
  width: 81px;
  height: 36px;
  font-size: 14px;
  background-color: white !important;
  border: 1px solid #ff5c09 !important;
  color: #ff5c09 !important;
  box-shadow: #989898;

}

.modalsaveBtn
{
  background-color: #ff5c09 !important;
  border: 1px solid #ff5c09 !important;
  color: #fff;
  box-shadow: none;
  border-radius: 10px;
  width: 80px;
  height: 35px;
  font-size: 14px;
}
.modalsaveBtn:hover {
  width: 81px;
  height: 36px;
  font-size: 14px;
  background-color: #ff5c09 !important;
  border: 1px solid #ff5c09 !important;
  color: #fff;
  box-shadow: #989898;

}

.main-footer {

  padding: 1.5rem;
  border-top: 0px solid #cccccc;
  z-index: 10;
/* position: absolute; */
  bottom: 0;
width:100%;
}

.newTag
{
margin-top: -20px; 
margin-left: -28px;
}

@media (max-width: 767px) {
  .newTag
  {
  margin-top: -20px; 
  margin-left: -28px;
  }
  .filter{
    width:100%;
  }
}

@media (max-width: 920px) {
  .newTag
  {
  margin-top: -90px;  
  margin-left: -28px;
  }
  .filter{
    width:100%;
  }
}

@media (max-width: 480px) {
  .newTag
  {
  margin-top: -20px;  
  margin-left: -28px;
  }
  .filter{
    width:100%;
  }
}

.jobcancelBtn
{
  background-color: white !important;
  border: 1px solid #ff5c09 !important;
  color: #ff5c09;
  box-shadow: none;
  border-radius: 10px;
  width: 110px;
  height: 35px;
  font-size: 14px;
}
.jobcancelBtn:hover {
  width: 111px;
  height: 36px;
  font-size: 14px;
  background-color: white !important;
  border: 1px solid #ff5c09 !important;
  color: #ff5c09 !important;
  box-shadow: #989898;

}

.jobsaveBtn
{
  background-color: #ff5c09 !important;
  border: 1px solid #ff5c09 !important;
  color: #fff;
  box-shadow: none;
  border-radius: 10px;
  width: 110px;
  height: 35px;
  font-size: 14px;
}
.jobsaveBtn:hover {
  width: 111px;
  height: 36px;
  font-size: 14px;
  background-color: #ff5c09 !important;
  border: 1px solid #ff5c09 !important;
  color: #fff;
  box-shadow: #989898;

}

.editBtn{
  background-color: #ff5c09 !important;
  box-shadow: none;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  width: 100px;
  height: 30px;
  color: white;

}
.editBtn:hover {
  height: 31px ;
  width: 101px;
  background-color: #ff5c09 !important;
  box-shadow: #989898;
  border:none;
  color: white;

}

.planBtn{
    padding: 0;
    margin: 0;
    height: 42px;
    width: 140px;
    background-color: #3D39D8 !important;
    border-radius: 36px;
    border: none;

}

.planBtn:hover{
 
  padding: 0;
  margin: 0;
  height: 45px;
  width: 150px;
  background-color: #3D39D8 !important;
  border-radius: 36px;
  border: none;

}

.editprofileBtn
{
  
    background-color: #000 !important;
    color: white !important;
    border-radius: 10px;
    box-shadow: none;
    border: none;
    width:110px;

}

.editprofileBtn:hover
{
  
    background-color: #000 !important;
    color: white !important;
    border-radius: 10px;
    box-shadow: none;
    border: none;
    width:111px;

}
@media (min-width: 921px) {
.filter{
  width:77%;
}
}

.newTagJob
{
margin-top: -25px; 
margin-left: -28px;
}

@media (max-width: 767px) {
  .newTagJob
  {
  margin-top: -25px; 
  margin-left: -28px;
  }

}

@media (max-width: 920px) {
  .newTagJob
  {
  margin-top: -25px;  
  margin-left: -28px;
  }

}

@media (max-width: 480px) {
  .newTagJob
  {
  margin-top: -42px;  
  margin-left: -28px;
  }

}

.newTagEvent
{
margin-top: -20px; 
margin-left: -28px;
}

@media (max-widh: 767px) {
  .newTagEvent
  {
  margin-top: -220px; 
  margin-left: -28px;
  }

}

@media (max-width: 920px) {
  .newTagEvent
  {
  margin-top: -20px;  
  margin-left: -28px;
  }

}

@media (max-width: 480px) {
  .newTagEvent
  {
  margin-top: -20px;  
  margin-left: -28px;
  }

}
.sidebar-menu-nav-title
{
  font-size: 16px;
}


.table-hover>tbody>tr:hover>* {
  background-color: #EFFFB2 ;
}

.table-hover tbody tr:hover {
  background-color : #EFFFB2;
}

.table-hover tbody tr
{
  height:50px;
  vertical-align: middle;
}

input[type="file"] {
  display: none;
}

/* Style the custom button */
.custom-button {
  display: inline-block;
  padding: 5px;
  border-radius:5px;
  background-color: #a020f0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.custom-buttonProfile {
  display: inline-block;
  border-radius:150px;
  color:#ff5c09;
 background: #f2f2f2;
 padding-left: 5px;
  font-size: 16px;
  cursor: pointer;
  height:25px;
  width:25px;

  margin-left: 80px;
}

.downloadBtn
{
  border: solid 1px #ff5c09 !important;
  padding: 3px;
  border-radius: 5px;
  color: #ff5c09;
}

.tab-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 16px;
  margin-right: 10px;
  color: #000;
  border-bottom: 2px solid transparent;
}

.tab-button.disabled {
  opacity: 0.5;
  border-bottom-color: orangered;
  pointer-events: none;
}

.tab-button:hover {
  border-bottom-color: orangered; /* Change to desired hover color */
}


.downloadBtn:hover
{
  border: solid 1px #ff5c09 !important;
  padding: 4px;
  border-radius: 5px;
  color: #ff5c09;
}
.breadcrumb-container {
  background: #ff5c09;
  /* background: radial-gradient(circle, rgba(9,34,255,0.5) 57%, rgba(255,255,255,1) 100%); */
  width: 100%;
  height: 100px;
 
  color:#fff;
}
.breadcrumb-containerabout{
 
    background: #ff5c09;
    /* background: radial-gradient(circle, rgba(9,34,255,0.5) 57%, rgba(255,255,255,1) 100%); */
    width: 100%;
    height: 60px;
   
    color:#fff;
  }
.bottom-div {

  z-index: 9;
  margin-top: 150px;
  background-color: #fff;
}


.text-grad {
  background:radial-gradient(circle, #ff5c09 0%, #a020f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-sub-title {
  line-height: 1.2;
  display: inline-block;
  color: #a020f0;
  font-size: 1.75rem;
  
}

.overviewcardAbout
{
  width: 95%;
  height: auto;
  background-color: #fbfbfb;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  margin-top: 10px;
  padding: 15px ;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(145, 92, 182,0.2);
}

select
{
    color: #000;
}
option
{
    color: #000;
}
option:first-child
{
    color: #000;
}
/* .pagination li:last-child {
  display: none;
} */

.myCarousel1 {
  background: #f3f8ff;
  margin-top: -12%;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 17dvh;
  border-radius: 10px;
}

.cookCard{
  border-radius: 10px;
  background: #f9f9f9;
  padding:12px 5px;
  margin:5px;


  }
  .cookCard:hover
  {
    box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
  }

  .webBtn1
  {
    background-color: rgba(255, 92, 9,0.9) !important;
    border:none;
    box-shadow: none;
    text-transform: capitalize;
    color: #fff;
    height: 42px;
    width: 120px;
    font-size: 1em;
    padding: 0px 20px;
    border-radius: 15px;
  }

  .webBtn1:hover
  {
    background-color: rgb(255, 92, 9) !important;
    border: solid 3px rgb(255, 92, 9) !important;
  }

  .webBtn2
  {
    background-color: #fff;
    border:solid 1px rgba(255, 92, 9,0.9) !important;
    box-shadow: none;
    text-transform: capitalize;
    color: #ff5c09;
    height: 42px;
    width: 120px;
    font-size: 1em;
    padding: 0px 20px;
    border-radius: 15px;
  }

  .webBtn2:hover
  {
    background-color: rgba(255, 92, 9,0.9) !important;
    border: none;
    color:#fff;
  }

  .profileBtn
  {
    height: 24px;
    width: 100px;
    padding: 0;
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
    border: none;
    background:rgba(255, 92, 9,0.9) !important;
  }



  .profileBtn:hover
  {
    background: #ff5c09 !important;
  }

  ::-ms-reveal {
    display: none;
}


.btn-primary:hover {
  background-color : none;
  border-color : none;
}
.btn-primary:focus, .btn-primary.focus {
  -webkit-box-shadow : 0 0 0 0.2rem rgba(102, 110, 232, 0.5);
          box-shadow : 0 0 0 0.2rem rgba(102, 110, 232, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color : #FFFFFF;
  background-color : #ff5c09;
  border-color : #ff5c09;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color : #fff;
  background-color : #fff;
  border-color : #fff;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow : 0 0 0 0.2rem rgba(102, 110, 232, 0.5);
          box-shadow : 0 0 0 0.2rem rgba(102, 110, 232, 0.5);
}


.sticky-container {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's above other content */
}

.sticky-div {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: left;
}

.download-button {
  padding: 10px 20px;
  background-color: #0077B5;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button {
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #005580;
}

.content {
  padding: 20px;
  /* Add some space at the bottom to prevent content from being hidden under the sticky div */
  margin-bottom: 60px; /* Adjust according to the height of the sticky div */
}

.section-bg {
  background: #fff9f1;
}
@media (min-width: 992px) {
  .padding-tb {
      padding: 110px 0;
  }
}
@media (min-width: 1200px) {
  .four-zero-section .four-zero-content .title {
      font-size: 100px;
      line-height: 1;
  }
}

.lab-btn {
  padding: 10px 30px;
  background: #ff5c09;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 700;
  color:#fff
}
.tablecontainer{
  background-color: white;
  align-items: center;
}

.table-custom {
  border-collapse: separate;
  border-spacing: 0;
  padding: 40px;
}

.table-custom thead th {
  border-bottom: 2px solid black;
  /* Black line after the heading */
}

.borderBt-tr {
  border-bottom: 2px solid black; 
  height: 100px !important;
}

.vertical-middle {
  vertical-align: middle !important;
}

.view_btn
{
  background-color: #ff5c09 !important;
  border: 1px solid #fff !important;
  color: #fff;
  box-shadow: none;
  border-radius: 10px;
  width: auto;
  height: 40px;
  font-size: 16px;
}


.view_btn:hover {
  height: 40px ;
  width: auto;
  background-color: #ff5c09 !important;
  box-shadow: #989898;
  border:none;

}

.perks .item {
  background-color: #F2F2F2;
  padding: 20px;
  padding: 10px 15px;
  border-radius: 30px;
  margin-right: 20px;
  font-weight: 400;
  cursor: pointer;
}

.perks .item-false {
  background-color: #F2F2F2;
  border: 1px solid  rgb(255, 92, 9);
  padding: 10px 15px;
  border-radius: 30px;
  color: rgb(255, 92, 9);
  margin-right: 20px;
  font-weight: 400;
  cursor: pointer;
}

.perks .item-job {
  background-color: #F2F2F2;
  border: 1px solid rgb(255, 92, 9);
  padding: 5px 5px;
  border-radius: 30px;
  color: rgb(255, 92, 9);
  font-weight: 400;
  font-size: 14px;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  cursor: pointer;
}

.perks .item-ejob {
  background-color: #F2F2F2;
  border: 1px solid rgb(255, 92, 9);
  padding: 5px 5px;
  border-radius: 30px;
  color: rgb(255, 92, 9);
  font-weight: 400;
  font-size: 12px;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  cursor: pointer;
}

.perks .item-false .icon {
  color: rgb(255, 92, 9);
  font-size: 25px;
}

.perks .item-job .icon {
  color: rgb(255, 92, 9);
  font-size: 20px;
  display: flex;
  align-items: center;
}

.perks .item .icon {
  font-size: 25px;
}