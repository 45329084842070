body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f2f2f2 !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,  "Roboto",
    monospace;
}



.eyebutton1{
  position: absolute;
  right: 280px;
  top: 65%;
  transform: translateY(-50%);
  cursor: pointer;
}

.eyebutton2{
  position: absolute;
  right: 280px;
  top: 73%;                  
  transform: translateY(-50%);
  cursor: pointer;
}
.eyebutton3{
  position: absolute;
  right: 280px;
  top: 69%;

  transform: translateY(-50%);
  cursor: pointer;
}
.eyebutton4{

position: absolute;
right: 280px;
top: 76%;                 
transform: translateY(-50%);
cursor: pointer;
}

@media (max-width: 1000px) {
 
.eyebutton1{
  position: absolute;
  right: 60px;
  top: 65%;
  transform: translateY(-50%);
  cursor: pointer;
}

.eyebutton2{
  position: absolute;
  right: 60px;
  top: 73%;                  
  transform: translateY(-50%);
  cursor: pointer;
}
.eyebutton3{
  position: absolute;
  right: 60px;
  top: 68%;

  transform: translateY(-50%);
  cursor: pointer;
}
.eyebutton4{

position: absolute;
right: 60px;
top: 76%;                 
transform: translateY(-50%);
cursor: pointer;
}

}



@import "react-loading-skeleton/dist/skeleton.css";

